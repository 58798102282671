<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
        <div
          class="main"
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span v-scroll-reveal="{ delay: 20 }">About</span>
        <h3 v-scroll-reveal="{ delay: 40 }">
          Founder & CEO of The Marketing & Advertising Agency of DORART MGMT
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          Hey!<br/>
          I'm Dorart, The Founder & CEO of The Marketing & Advertising Agency of DORART MGMT.
          <br/>
          Our company was founded in December 2019, starting off to operate in the United States, whereas focusing on getting clientele such areas as Los Angelos, Miami, New York, etc.
          Now we are Operating in the Entire USA and are well-known all over South and North America, even in most of Europe. 
          We provide pretty much all social media services there are out there, but mainly focusing on Instagram.
          <br/>
          As of Instagram, we do all sorts of stuff, Celebrity Giveaways, Organic Ads via hand dms, but we are well known for our "Unique Growth Program" which is yet to be beaten, as it's truly the most innovative thing that's been created in the market of Instagram as of today, which has been created by the Founder, Dorart.
          We have a client base of over 1,000 clients signed with DORART MGMT, and we have over 10,000 monthly recurring clients from our partners.
          We look foward to new people blowing up their instagram pages every day, to make the world better, and to make the right people successful who choose to attend this program, All within TOS of Instagram.
        </p>
      </div>
      <!-- <div class="shane_tm_button" v-scroll-reveal="{ delay: 80 }">
        <a :href="cv" download>
          Download CV
        </a>
      </div> -->
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/dorart.jpg"),
      cv: require("../../assets/img/resume/resume.jpg"),
    };
  },
};
</script>
