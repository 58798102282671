<template>
  <div class="shane_tm_section">
    <CoolLightBox :items="youtubeItems" :index="index" @close="index = null">
    </CoolLightBox>
    <div
      class="shane_tm_business_video"
      v-for="(image, imageIndex) in youtubeItems"
      :key="imageIndex"
    >
      <div class="bg">
        <div
          class="image jarallax"
          :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
        ></div>
        <div class="overlay"></div>
      </div>
      <!-- End .bg -->

      <div class="content">
        <span
          class="rounded"
          @click="index = imageIndex"
          v-scroll-reveal="{ delay: 50, distance: '50px', duration: 1000 }"
        ></span>

        <h2
          class="text"
          v-scroll-reveal="{ delay: 100, distance: '50px', duration: 1200 }"
        >
       Why us
        </h2>
       

        <div v-scroll-reveal="{ delay: 100, distance: '50px', duration: 1200 }" v-html="content"></div>

        <div
          class="shane_tm_video_button"
          v-scroll-reveal="{ delay: 150, distance: '50px', duration: 1400 }"
        >
          <button class="popup-youtube" @click="index = imageIndex">
            Watch Video
          </button>
        </div>
      </div>
      <!-- End .content -->
    </div>
    <!-- End .shane_tm_business_video -->
  </div>
  <!-- End .shane_tm_section -->
</template>

<script>
export default {
  data() {
    return {
      content:` <ul>
                <li><h4 style="color: white;">Organic IG Followers growth</h4></li>
                <li><h4 style="color: white;">Satisfaction guaranteed</h4></li>
                <li><h4 style="color: white;">Natural and manual engagement</h4></li>
                <li><h4 style="color: white;">New Engagements from a Target Audience</h4></li>
                <li><h4 style="color: white;">24/7 customer support</h4></li>
              </ul>
              <br/>
              <br/>
              <br/>`,
      index: null,
      youtubeItems: [
        {
          src: "../../assets/img/video/video.mp4",
          thumb: require("../../assets/img/video/1.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
