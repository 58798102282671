<template>
  <div class="news_list">
    <ul>
      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 50 }">
          <div class="image">
            <img src="../../assets/img/news/ustimesnow.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal"
              :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <h3 class="title" @click="showModal">
             Dorart Ibrahimi helps people gain folowers and become milionares
            </h3>
            <p class="date">
              By <a href="#">Brand Buzz</a> <span>13 Sep 2022</span>
            </p>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive }"
            id="modal"
            @click="closeModal"
            v-if="isVisible"
          >
            <div class="modal-dialog modal-dialog-centered " @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->

                <div class="shane_tm_modalbox_news">
                  <div class="box_inner">
                    <div class="image">
                      <img
                        src="../../assets/img/placeholders/3-4.jpg"
                        alt="thumb"
                      />
                      <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
                      ></div>
                    </div>
                    <!-- End image -->
                    <div class="details">
                      <a href="https://www.ustimesnow.com/dorart-ibrahimi-helps-people-gain-followers-and-become-millionaires-through-social-media/" target="_blank" class="title">
                        Dorart Ibrahimi helps people gain folowers and become milionares
                      </a>
                      <p class="date">
                        By <a href="https://www.ustimesnow.com/author/us-times-now-brand-buzz/" target="_blank">Brand Buzz</a> <span>13 Sep 2022</span>
                      </p>
                    </div>
                    <!-- End details -->
                                        <!-- End description -->

                    <!-- <div class="news_share">
                      <Social />
                    </div> -->
                    <!-- End news share -->
                  </div>
                  <!-- End .box_inner -->
                </div>
                <!-- shane_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 100 }">
          <div class="image">
            <img src="../../assets/img/placeholders/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal2"
              :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <h3 class="title" @click="showModal2">
              Dorart Ibrahimi helps people gain followers and become millionaires through social media
            </h3>
            <p class="date">
              By <a href="https://therealpreneur.com/profile/therealpreneur" target="_blank">	The Real Preneur </a> <span>08 Sep 2022</span>
            </p>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive2 }"
            id="modal"
            @click="closeModal2"
            v-if="isVisible2"
          >
            <div class="modal-dialog modal-dialog-centered " @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal2">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="shane_tm_modalbox_news">
                  <div class="box_inner">
                    <div class="image">
                      <img
                        src="../../assets/img/placeholders/4-3.jpg"
                        alt="thumb"
                      />
                      <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + imgSrc2 + ')' }"
                      ></div>
                    </div>
                    <!-- End image -->
                    <div class="details">
            <a href="https://therealpreneur.com/dorart-ibrahimi-helps-people-gain-followers-and-become-millionaires-through-social-media" target="_blank" class="title" @click="showModal2">
              Dorart Ibrahimi helps people gain followers and become millionaires through social media
            </a>
            <p class="date">
              By <a href="https://therealpreneur.com/profile/therealpreneur" target="_blank">	The Real Preneur </a> <span>08 Sep 2022</span>
            </p>
          </div>
       
                  </div>
                  <!-- End .box_inner -->
                </div>
                <!-- shane_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li -->

      <li>
        <div class="list_inner" v-scroll-reveal="{ delay: 150 }">
          <div class="image">
            <img src="../../assets/img/placeholders/4-3.jpg" alt="thumb" />
            <div
              class="main"
              @click="showModal3"
              :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
            ></div>
          </div>
          <!-- End image -->

          <div class="details">
            <h3 class="title" @click="showModal3">
              This is how Dorart Ibrahimi grew a million-dollar company at just 16
            </h3>
            <p class="date">
              <a href="#"></a> <span>Published: Sept. 8, 2022 at 3:11 a.m. ETt</span>
            </p>
          </div>
          <!-- End details -->
        </div>

        <!-- Start Modalbox -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive3 }"
            id="modal"
            @click="closeModal3"
            v-if="isVisible3"
          >
            <div class="modal-dialog modal-dialog-centered " @click.stop>
              <div class="modal-content">
                <div class="close" @click="closeModal3">
                  <img
                    class="svg"
                    src="../../assets/img/svg/cancel.svg"
                    alt="cancel-img"
                  />
                </div>
                <!-- End .close -->
                <div class="shane_tm_modalbox_news">
                  <div class="box_inner">
                    <div class="image">
                      <img
                        src="../../assets/img/placeholders/4-3.jpg"
                        alt="thumb"
                      />
                      <div
                        class="main"
                        :style="{ backgroundImage: 'url(' + imgSrc3 + ')' }"
                      ></div>
                    </div>
                    <!-- End image -->
                    <div class="details">
                      <a href="https://www.marketwatch.com/press-release/this-is-how-dorart-ibrahimi-grew-a-million-dollar-company-at-just-16-2022-09-08?mod=search_headline" target="_blank" class="title">
                        This is how Dorart Ibrahimi grew a million-dollar company at just 16
                      </a>
                      <p class="date">
                        <a href="#"></a> <span>Published: Sept. 8, 2022 at 3:11 a.m. ETt</span>
                      </p>
                    </div>
                    <!-- End details -->

                    <!-- End description -->
                    <!-- End news share -->
                  </div>
                  <!-- End .box_inner -->
                </div>
                <!-- shane_tm_modalbox_news -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End Modalbox -->
      </li>
      <!-- End li -->
    </ul>
  </div>
</template>

<script>
//import Social from "../Social.vue";
export default {
  components: {
   // Social,
  },
  data() {
    return {
      isActive: false,
      isActive2: false,
      isActive3: false,
      isVisible: false,
      isVisible2: false,
      isVisible3: false,
      imgSrc: require("../../assets/img/news/ustimesnow.jpg"),
      imgSrc2: require("../../assets/img/news/therealprenur.jpg"),
      imgSrc3: require("../../assets/img/news/MarketWatch.jpg"),
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    showModal2: function() {
      this.isActive2 = true;
      this.isVisible2 = true;
    },
    showModal3: function() {
      this.isActive3 = true;
      this.isVisible3 = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
    closeModal2: function() {
      this.isActive2 = false;
      this.isVisible2 = false;
    },
    closeModal3: function() {
      this.isActive3 = false;
      this.isVisible3 = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
