<template>
  <div class="container">
    <div
      class="positon-relative"
    >
    <!-- v-scroll-reveal="{ delay: 50, distance: '20px' }" -->
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portfolio</span>
            <h3>Latest Client reviews & Giveaways</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >Client reviews</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Giveaways</a
            >
          </li>
          <!-- End Mix tabcontent 4 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <CoolLightBox :items="clientReviews" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in clientReviews" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry shane_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>Client feedback</h3>
                <span>Galery</span>
                <!-- <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span> -->
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="giveAways"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in giveAways" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 2 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      clientReviews: [],
      giveaways: [],
      domain:'https://www.dorartmgmt.net',
      index: null,
    };
  },
  methods:{
    loadClientReviews(){
            this.$store.dispatch("ApiValues/getClientReviews").then((response) => {
            this.clientReviews = response.data
        });
  },
  loadGiveAways(){
            this.$store.dispatch("ApiValues/getGiveAways").then((response) => {
            this.giveAways = response.data
        });
  }
},
created(){
  this.loadClientReviews();
  this.loadGiveAways();
}
};
</script>

<style lang="scss" scoped></style>
