<template>
<div class="container">
    <div class="positon-relative">
        <div class="row">
            <div class="shane_tm_title">
                <div class="title_flex">
                    <div class="left">
                        <span>Packages</span>
                        <h3>Packages</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio_list">
                <ul>
                <li v-for="(p,index) in this.packages" :key="index">
                    <div class="card">
                        <div class="container2">
                            <h5 style="color: white;"><b>{{p.Title}}</b></h5>
                            <p v-text="p.Description"></p>
                            <a href="#contact" class="white-fill-bg btn-outline btn-medium card-button" style="position: absolute;">
                                Contact us
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        </div>
    </div>

</template>
<script>
export default {
    created(){
    this.loadPackages();
  },
    methods:{
        loadPackages(){
            this.$store.dispatch("ApiValues/getPackages").then((response) => {
            this.packages = response.data
        });

        // console.log('START',this.packages);

        // if( this.packages.length < 4){
        //     console.log('<4');
        //     let differnce = 4 - this.packages.length;
        //     for(let i = 0; i<differnce; i++){
        //         const obj = {
        //         Id: 0,
        //         Title: "",
        //         Description: "",
        //         Price: 0
        //     }
        //     this.packages.push(obj);
        //     }
        //     console.log('<4 END',this.packages);

        // }else if(this.packages.length > 4 && this.packages.length < 8){
        //     console.log('>4');

        //     let differnce = 8 - this.packages.length;
        //     for(let i = 0; i<differnce; i++){
        //         const obj = {
        //         Id: 0,
        //         Title: "",
        //         Description: "",
        //         Price: 0
        //     }
        //     this.packages.push(obj);
        //     }
        //     console.log('>4 END',this.packages);

        // }else if(this.packages.length > 8 && this.packages.length < 12){
        //     let differnce = 12 - this.packages.length;
        //     for(let i = 0; i<differnce; i++){
        //         const obj = {
        //         Id: 0,
        //         Title: "",
        //         Description: "",
        //         Price: 0
        //     }
        //     this.packages.push(obj);
        //     }
        // }
        // console.log(this.packages);
         }
    },
    data() {
        return {
            packages:[],
        }
    }
    
}
</script>
<style>
.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    transition: 0.3s;
    width: 100%;
    height: 370px;
    border-radius: 5px;
    background-color: black;
    color: white;
    margin: 8px;
    position:relative;
    padding: 20px;

    /* float: left; */
}
.card-button {
    position: absolute;
    bottom: 20px;
    right: 25%;
}

.card:hover {
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.4);
}

img {
    border-radius: 5px 5px 0 0;
}

.container2 {
    padding: 2px 16px;
}

* {
    box-sizing: border-box;
}

/* Container for flexboxes */
.row {
    display: flex;
    flex-wrap: wrap;
}

/* Create four equal columns */
.column {
    flex: 25%;
    padding: 20px;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
    .column {
        flex: 50%;
    }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .row {
        flex-direction: column;
    }
}
</style>