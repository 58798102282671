<template>
  <div class="tokyo_tm_contact">
    <div class="fields">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" class="contact_form" @submit.prevent="handleSubmit(sendEmail)">
          <div class="first">
            <ul>
              <ValidationProvider
                name="user_name"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    v-model="formData.name"
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>

              <ValidationProvider
                name="user_email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <li>
                  <input
                    type="text"
                    rules="required|email"
                    v-model="formData.email"
                    placeholder="email"
                  />
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
              <ValidationProvider
                name="message"
                rules="required"
                v-slot="{ errors }"
              >
                <li>
                  <textarea
                    v-model="formData.message"
                    placeholder="Message"
                  ></textarea>
                  <span class="inpur-error">{{ errors[0] }}</span>
                </li>
              </ValidationProvider>
            </ul>
          </div>
          <div class="tokyo_tm_button">
            <button type="submit" class="white-fill-bg fill-black">
              Send Message
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import emailjs from 'emailjs-com'; 
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props:['close'],
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    sendEmail() {
      try {
        emailjs.send("service_yek4iis","template_dwjktib",{
          from_name: this.formData.name + ' email:' + this.formData.email,
          to_name: "Dorart",
          message: this.formData.message,
          }, "K2qIfooYW7p29Aytw")

          this.$parent.closeModal();
         
      // Reset form field
      this.formData.name = ''
      this.formData.email = ''
      this.formData.message = ''

      } catch(error) {
          console.log({error})
      }

    },
  },
  
};
</script>
