<template>
  <div class="share">
    <span>Share:</span>
    <ul class="social">
      <li v-for="(social, i) in socialList" :key="i">
        <a :href="social.link" target="_blank" rel="noreferrer"
          ><img class="svg" :src="social.src" alt="social"
        /></a>
      </li>
    </ul>
    <!-- End. social -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      socialList: [
      {
          link: "https://www.instagram.com/dorartmgmt/?igshid=MDE2OWE1N2Q%3D",
          src: require("../assets/img/svg/social/instagram.svg"),
        },
        {
          link: "https://www.instagram.com/dorartxk/?igshid=MDE2OWE1N2Q%3D",
          src: require("../assets/img/svg/social/instagram.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
