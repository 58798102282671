<template>
    <div class="about_inner">
      <div class="left">
        <div class="image">
          <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
          <div
            class="main"
            v-scroll-reveal="{ delay: 20 }"
            :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
          ></div>
        </div>
        <!-- End image  -->
      </div>
      <!-- End left -->
  
      <div class="right">
        <div class="shane_tm_title">
          <h2 v-scroll-reveal="{ delay: 40 }">
            HOW IT WORKS
          </h2>
        </div>
        <div class="shane_tm_button" v-scroll-reveal="{ delay: 80 }">
          <a href="#contact">
            Contact us
          </a>
        </div>
      </div>
      <!-- End right -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        aboutImg: require("../../assets/img/TopOffer/Top2.jpg"),
        cv: require("../../assets/img/resume/resume.jpg"),
      };
    },
  };
  </script>
  